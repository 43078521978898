import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b50d444 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _b939e61c = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _484b0e46 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _5d380af6 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3de87f5e = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _dd34e0ee = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _25ae577a = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _38fa2ccd = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _e0a8d6cc = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _23a3c2ef = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _6d82883c = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _91ffe7d2 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _17475b60 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _c9e677c8 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _d0410fc8 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _b7cfbd58 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _36c12f42 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _34796428 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _7fe7f320 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _29cbf06e = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _3d7a94bf = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _77b4c93f = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _72ad3aa6 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _2f2a9278 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _08f67740 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _bc65a134 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _67d89d6e = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _05569169 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _f370a19a = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _e589dbd0 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _9b863518 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _36a05aee = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _b1bcf0be = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _3b1fa0a6 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _3f3f37aa = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _12a07b19 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _3389b536 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _4beab637 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _0a989a48 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _52839756 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _bf6ff5fe = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _3584000e = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _5af8a5c4 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _76cfe637 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _3d2f069c = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _1ea68fb1 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _fd43d146 = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _776e7107 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _1b7d5e9a = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _4301213e = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _61d5fbd8 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _08ac901e = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _2c8e2f44 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _69109c5a = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _399eb534 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _45e0b988 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _467d16b7 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _6db728b6 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _6e949c74 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _6b50d444,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _6b50d444,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _6b50d444,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _6b50d444,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _6b50d444,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _6b50d444,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _6b50d444,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _6b50d444,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _b939e61c,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _484b0e46,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _5d380af6,
    name: "account"
  }, {
    path: "/cart",
    component: _6b50d444,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _3de87f5e,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _dd34e0ee,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _25ae577a,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _38fa2ccd,
    name: "designs"
  }, {
    path: "/facets",
    component: _e0a8d6cc,
    name: "facets"
  }, {
    path: "/favorites",
    component: _23a3c2ef,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _6d82883c,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _91ffe7d2,
    name: "health"
  }, {
    path: "/not-found",
    component: _17475b60,
    name: "not-found"
  }, {
    path: "/proof",
    component: _c9e677c8,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _d0410fc8,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _b7cfbd58,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _36c12f42,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _34796428,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _7fe7f320,
    name: "signin"
  }, {
    path: "/signout",
    component: _29cbf06e,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _3d7a94bf,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _77b4c93f,
    name: "testy"
  }, {
    path: "/version",
    component: _72ad3aa6,
    name: "version"
  }, {
    path: "/version-app",
    component: _2f2a9278,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _08f67740,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _bc65a134,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _67d89d6e,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _05569169,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _f370a19a,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _e589dbd0,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _b939e61c,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _484b0e46,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _9b863518,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _36a05aee,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _b1bcf0be,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _3b1fa0a6,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _3f3f37aa,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _12a07b19,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _3389b536,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _4beab637,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _0a989a48,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _52839756,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _bf6ff5fe,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _3584000e,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _5af8a5c4,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _76cfe637,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _3d2f069c,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _1ea68fb1,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _fd43d146,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _776e7107,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _1b7d5e9a,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _4301213e,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _61d5fbd8,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _08ac901e,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _2c8e2f44,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _69109c5a,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _399eb534,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _45e0b988,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _467d16b7,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _6db728b6,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _6e949c74,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
